<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(saveForm)"
        @reset.prevent="resetForm"
      >

        <!-- Descripcion -->
        <b-form-group
          label="Descripción"
          label-for="descripcion"
        >
          <b-form-input
            id="descripcion"
            v-model="formData.descripcion"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="Trabajador"
          label-for="trabajador"
        >
          <v-select
            v-model="formData.trabajador"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nombreCompleto"
            :options="selectTrabajador"
          />
        </b-form-group>
        <b-form-group
          label="Responsable"
          label-for="responsable"
        >
          <b-form-input
            id="responsable"
            v-model="formData.responsable"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')

    const formData = ref({})
    const selectTrabajador = ref([])
    const resetForm = () => {
      formData.value = {}
    }
    const getParametros = async () => {
      await store.dispatch('rrhh/TRABAJADOR_FIND_ALL', { cargo: '', limit: -1, page: -1, tipo: 'grilla', query: '', sortBy: '',
      })
        .then(response => {
          // console.info('ubigeo', response.ubigeo)
          if (response) {
            selectTrabajador.value = response.items
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const saveForm = () => {
      let service = 'logistica/ALMACEN_CREATE'
      if (props.formType === 'edit') {
        service = 'logistica/ALMACEN_UPDATE'
      }
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Almacén'
      }
      if (props.formType === 'edit') {
        formData.value = props.dataEdit
        titleForm.value = 'Editar Almacén'
      }
    }
    getParametros()
    return {
      formData,
      saveForm,
      resetForm,
      toggleSidebar,
      titleForm,
      getParametros,
      selectTrabajador,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
