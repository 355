<template>
  <div>
    <b-card
      title="Input Groups with Buttons"
      no-body
      class="mb-0 pt-2 pl-2 pr-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Titulo"
                rules="required"
              >
                <b-form-group
                  label="Titulo"
                  label-for="titulo"
                >
                  <b-form-input
                    v-model="formData.titulo"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Justificacion"
                rules="required"
              >
                <b-form-group
                  label="Justificacion"
                  label-for="justificacion"
                >
                  <b-form-input
                    v-model="formData.justificacion"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Almacen"
                rules="required"
              >
                <b-form-group
                  label="Almacen"
                  label-for="almacen"
                >
                  <v-select
                    v-model="formData.almacen"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectAlmacen"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Forma Pago"
                rules="required"
              >
                <b-form-group
                  label="Forma Pago"
                  label-for="formaPago"
                >
                  <v-select
                    v-model="formData.formaPago"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectFormaPago"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar Producto"
                label-for="producto"
              >
                <v-select
                  v-model="formData.preProducto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="productoNombre"
                  :options="selectProducto"
                  @input="changeProducto"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Precio"
                label-for="precio"
              >
                <b-form-input
                  v-model="formData.prePrecio"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Cantidad"
                label-for="Cantidad"
              >
                <b-form-input
                  v-model="formData.preCantidad"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Unidad M."
                label-for="producto"
              >
                <v-select
                  v-model="formData.preUnidadMedida"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descripcion"
                  :options="selectunidadMedida"
                  @input="changeunidadMedida"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-col md="6">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="addDetalle"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="items"
                responsive
                :fields="fieldsDetalle"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros"
                :busy="isBusy"
              >
                <!-- Column: precio -->
                <template #cell(productoNombre)="data">
                  <b-form-input
                    v-if="!data.item.isService"
                    v-model="data.item.productoNombre"
                    :disabled="true"
                    size="sm"
                  />
                  <b-form-input
                    v-if="data.item.isService"
                    v-model="data.item.productoNombre"
                    size="sm"
                  />
                </template>
                <template #cell(inputUnidadMedida)="data">
                  <v-select
                    v-model="data.item.unidadMedida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="[data.item.unidadMedida]"
                    disabled
                    class="select-size-sm"
                  />
                </template>
                <!-- Column: precio -->
                <template #cell(inputPrecio)="data">
                  <b-form-input
                    v-model="data.item.precio"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'P')"
                  />
                </template>
                <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    :disabled="true"
                    @input="changeCantidadPrecio(data.item,'S')"
                  />
                </template>
                <template #cell(inputCantidadReal)="data">
                  <b-form-input
                    v-model="data.item.cantidadReal"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                  />
                </template>
                <template #cell(inputIgv)="data">
                  <b-form-input
                    v-model="data.item.igv"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'I')"
                  />
                </template>
                <template #cell(inputFlete)="data">
                  <b-form-input
                    v-model="data.item.flete"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'F')"
                  />
                </template>
                <template #cell(inputPrecioCompra)="data">
                  <b-form-input
                    v-model="data.item.precioCompra"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    :disabled="true"
                    @input="changeCantidadPrecio(data.item,'PC')"
                  />
                </template>
                <!-- Column: Cantidad -->
                <template #cell(inputCantidad)="data">
                  <b-form-input
                    v-model="data.item.cantidad"
                    type="number"
                    size="sm"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'C')"
                  />
                </template>
                <!-- Column: Subtotal -->
                <!-- <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    readonly
                    size="sm"
                  />
                </template> -->
                <!-- Column: Actions -->
                <template #cell(acciones)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="deleteDetalle(data.item)"
                    >
                      <feather-icon icon="XSquareIcon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="2"
            />
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Total Flete"
                label-for="totalFlete"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalFlete"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Sub Total"
                label-for="subTotal"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalSub"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Total Igv"
                label-for="totalIgv"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalIgv"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Total"
                label-for="total"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalFinal"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            />
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                type="submit"
                size="lg"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            />
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, VBTooltip, BCol, BRow, BFormGroup, BForm, BFormInput, BButton, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { useNotify } from '@/helpers/toast'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    BCard,
    // BFormDatepicker,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BFormInput,
    // BInputGroup,
    // BInputGroupAppend,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BTable,
    BDropdown,
    BDropdownItem,
    // BFormFile,
    // BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataEdit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const isEdit = ref(false)
    const { notify } = useNotify(context)
    const titleForm = ref('')
    const sortBy = ref(null)
    const isBusy = ref(false)
    const isVentaRelacionado = ref(false)
    const fileToUpload = ref(null)
    const blankData = {
      titulo: '',
      justificacion: '',
      prePrecio: 0,
      preCantidad: 0,
      preUnidadMedida: {},
      totalFlete: parseFloat(0).toFixed(2),
      totalIgv: parseFloat(0).toFixed(2),
      totalSub: parseFloat(0).toFixed(2),
      totalFinal: parseFloat(0).toFixed(2),
      formaPago: { descripcion: 'CONTADO' },
    }
    const presentaciones = ref([])
    const presentacionSel = ref({})
    const items = ref([])
    const fieldsDetalle = ref([
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '30%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '12%' } },
      { key: 'inputSubtotal', label: 'Sub total', thStyle: { width: '7%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '7%' } },
      { key: 'inputPrecio', label: 'P. Unitatio', thStyle: { width: '7%' } },
      // { key: 'inputCompra', label: 'P.Compra', thStyle: { width: '14%' } },
      //   { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '14%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
    ])
    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }
    const almacenOrigen = ref([])
    const selectTipoVenta = ref([])
    const selectMotivo = ref([])
    const selectFormaPago = ref([
      { descripcion: 'CREDITO' },
      { descripcion: 'CONTADO' },
    ])
    const selectAlmacen = ref([])
    const selectProveedor = ref([])
    const selectunidadMedida = ref([])
    const documentoClienteRef = Vue.ref<HTMLInputElement | null>(null)
    const selectTipoIgv = ref([
      // { descripcion: 'CLIENTE' },
      { descripcion: 'SIN IGV' },
      { descripcion: 'IGV INCLUIDO' },
      // { descripcion: 'IGV INAFECTO' },
    ])
    const selectMoneda = ref([
      { descripcion: 'SOLES' },
      { descripcion: 'DOLARES' },
    ])
    const selecTipoTransporte = ref([
      { descripcion: 'TRANSPORTE PUBLICO', id: '01' },
      // { descripcion: 'TRANSPORTE PRIVADO', id: '02' },
    ])
    const isMonedaSol = ref(false)
    const selectPesoTipoUnidadMedida = ref([
      { descripcion: 'KGM' },
      { descripcion: 'TNE' },
    ])
    const selectProducto = ref([])
    const initData = () => {
      // console.log('denyss')
      formData.value.tipo_de_transporte = { descripcion: 'TRANSPORTE PUBLICO', id: '01' }
      formData.value.motivo_de_traslado = { descripcion: 'TRASLADO EMISOR ITINERANTE CP', id: '18' }
      // formData.value.tipoDocumento = {}
    }
    const formatDateSend = date => {
      console.info('formatDateSend', date)
      let response = ''
      if (date !== null && date !== undefined && typeof (date) !== 'string') {
        response = `${date.day}-${date.month}-${date.year}`
      }
      if (typeof (date) === 'string') {
        const partDate = date.split('-')
        response = `${partDate[2]}-${partDate[1]}-${partDate[0]}`
      }
      return response
    }

    const getParametros = async () => {
      const dataUser = JSON.parse(localStorage.getItem('userData'))
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          selectMotivo.value = []
          Vue.swal.showLoading()
          await store.dispatch('logistica/COMPRA_LOGISTICA_PARAMETROSL', { idTrabajador: dataUser.idTrabajador,
          })
            .then(response => {
              // console.info('ubigeo', response.ubigeo)
              if (response) {
                selectAlmacen.value = [response.almacen]
                formData.value.almacen = response.almacen
                selectProveedor.value = response.proveedor
                selectProducto.value = response.producto
                selectProducto.value.forEach((element, index) => {
                  selectProducto.value[index].productoNombre = `${element.codigo} - ${element.descripcion} [${element.marcaProducto.descripcion}]`
                })
              }
              // response.items.forEach((element, index) => {
              //   if (index === 0) {
              //     formData.value.tipoMovimiento = element
              //   }
              // })
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
          Vue.swal.close()
        },
      })
    }

    const getProveedor = () => {
      console.log(formData.value.proveedor)
      formData.value.direccionProveedor = formData.value.proveedor.direccion
      formData.value.rucProveedor = formData.value.proveedor.ruc
    }
    const formParse = () => {
      const form = {
        pedidoCompra: {},
        detallePedidoCompra: [],
      }
      const dataUser = JSON.parse(localStorage.getItem('userData'))
      const sunatVenta = {
        almacen: formData.value.almacen,
        titulo: formData.value.titulo.toUpperCase(),
        justificacion: formData.value.justificacion.toUpperCase(),
        trabajador: { idTrabajador: dataUser.idTrabajador },
        subTotal: formData.value.totalSub,
        total: formData.value.totalFinal,
        formaPago: formData.value.formaPago.descripcion,
        // anio: '',
        // codigo: '',
        // correlativo: 0,
        fechaRegistro: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
        estado: true,
      }
      if (isEdit.value) {
        const editData = JSON.parse(props.dataEdit)
        sunatVenta.idPedidoCompra = editData.idPedidoCompra
      }
      form.pedidoCompra = sunatVenta
      items.value.forEach(element => {
        // console.log(element)
        const row = {
          pedidoCompra: { idPedidoCompra: 0 },
          producto: { idProducto: element.idProducto },
          cantidadSolicitada: element.cantidad,
          // subtotal: element.subtotal,
          precioActual: element.precio,
          observacion: '',
          cantidadAceptada: 0,
          precioAnterior: 0,
          estado: true,
        }
        if (isEdit.value) {
          row.idDetallePedidoCompra = element.idDetallePedidoCompra === undefined ? 0 : element.idDetallePedidoCompra
        }
        form.detallePedidoCompra.push(row)
      })
      console.log(JSON.stringify(form))
      return (form)
    }
    const onSubmit = async () => {
      try {
        Vue.swal({
          title: 'Registrando el Pedido de Compra',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            Vue.swal.showLoading()
            let service = 'logistica/PEDIDO_COMPRA_LOGISTICA_CREATE'
            if (isEdit.value) {
              service = 'logistica/PEDIDO_COMPRA_LOGISTICA_UPDATE'
            }
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, formParse())
              .then(response => {
                Vue.swal.close()
                notify('Respuesta', response.message, 'success')
                items.value = []
                resetData()
                getParametros()
              })
              .catch(error => {
                Vue.swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        Vue.swal.close()
        notify('Error', error.message, 'danger')
      }
    }

    const changeProducto = () => {
      console.log(formData.value.preProducto)
      // formData.value.prePrecio = (formData.value.preProducto.precioDefault).toFixed(2)
      selectunidadMedida.value = [formData.value.preProducto.unidadMedida]
      formData.value.preUnidadMedida = formData.value.preProducto.unidadMedida
    }
    const changeunidadMedida = () => {
      console.log('unidad')
    }
    const cleanPreData = () => {
      formData.value.preCantidad = null
      formData.value.prePrecio = null
      formData.value.preProducto = {}
    }
    const searchKey = row => {
      let seacrh = ''
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          seacrh = index
        }
      })
      return seacrh
    }
    const calcularSubtotal = () => {
      let subtotal = 0
      let flete = 0
      let igv = 0
      items.value.forEach(value => {
        subtotal += Number(value.subtotal)
        flete += 0 // (Number(value.flete) * Number(value.cantidad))
        igv += 0 // (Number(value.igv) * Number(value.cantidad))
      })
      formData.value.totalFlete = parseFloat(Number(flete)).toFixed(2)
      formData.value.totalIgv = parseFloat(Number(igv)).toFixed(2)
      formData.value.totalSub = parseFloat(Number(subtotal)).toFixed(2)
      formData.value.totalFinal = parseFloat(Number(subtotal) + Number(igv)).toFixed(2)
    }
    // const calculateTotal = () => {
    //   let subT = 0
    //   items.value.forEach(element => {
    //     subT += Number(element.subtotal)
    //   })
    //   formData.value.subTotal = parseFloat(subT).toFixed(2)
    // }
    const addDetalle = () => {
      try {
        // const valPro = formData.value.preProducto === null
        // console.log({ valPro })
        // console.info(formData.value.preProducto, formData.value.preCantidad, formData.value.prePrecio)
        // VALIDAR CANTIDAD
        if (formData.value.preProducto === '' || formData.value.preProducto === 0 || formData.value.preProducto === undefined || formData.value.preProducto === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE SELECCIONAR UN PRODUCTO')
        }
        if (formData.value.preCantidad === '' || formData.value.preCantidad === 0 || formData.value.preCantidad === undefined || formData.value.preCantidad === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE INGRESAR LA CANTIDAD')
        }
        // VALIDAR PRECIO
        if (formData.value.prePrecio === '' || formData.value.prePrecio === 0 || formData.value.prePrecio === undefined || formData.value.prePrecio === null) {
          formData.value.prePrecio.focus()
          throw new Error('TIENE QUE INGRESAR EL PRECIO')
        }
        // VALIDAR SI EXISTE EL PRODUCTO
        const igvPre = 0 // formData.value.tipoIgv.descripcion === 'IGV INCLUIDO' ? parseFloat((Number(formData.value.prePrecio) * 0.18)).toFixed(2) : parseFloat(0).toFixed(2)
        // console.log(formData.value.preProducto)
        const row = {
          ...formData.value.preProducto,
          precio: parseFloat(formData.value.prePrecio).toFixed(2),
          cantidad: parseFloat(formData.value.preCantidad).toFixed(2),
          cantidadReal: parseFloat(0).toFixed(2),
          igv: igvPre,
          flete: parseFloat(0).toFixed(2),
          precioCompra: parseFloat(Number(formData.value.prePrecio) + Number(igvPre) + Number(0)).toFixed(2),
          subtotal: parseFloat(formData.value.prePrecio * formData.value.preCantidad).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          unidadMedida: formData.value.preUnidadMedida,
          isService: false }
        if (searchKey(row) !== '') {
          throw new Error('EL PRODUCTO YA FUE AGREDADO')
        }
        console.log({ row })
        items.value.push(row)
        formData.value.preUnidadMedida = []
        formData.value.preCantidad = null
        cleanPreData()
        formData.value.preProducto = null
        calcularSubtotal()
        notify('', 'Se agrego el detalle', 'success')
      } catch (error) {
        console.log(error)
        notify('Error', error.message, 'danger')
      }
    }

    const deleteDetalle = row => {
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          items.value.splice(index, 1)
        }
      })
      calcularSubtotal()
    }
    const changeCantidadPrecio = (row, tipo) => {
    // precioCompra = PC
      console.info(row, tipo, items)
      items.value.forEach((element, index) => {
        if (element.key === row.key) {
          items.value[index].subtotal = parseFloat(Number(row.cantidad) * Number(row.precio)).toFixed(2)
          items.value[index].precioCompra = parseFloat(Number(row.precio) + Number(row.igv) + Number(row.flete)).toFixed(2)
        }
      })
      calcularSubtotal()
      // row.subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
    }
    const handleVehicle = data => {
      if (data?.mtcPartida) {
        formData.value.numero_de_registro_mtc = data.mtcPartida
      } else {
        formData.value.numero_de_registro_mtc = ''
        notify('Error', 'El vehículo no cuenta con registro de mercancía en el MTC.', 'danger')
      }
    }
    isEdit.value = false
    console.log(props.dataEdit)
    if (props.dataEdit !== '') {
      const dataEdit = JSON.parse(props.dataEdit)
      console.log('Data recibida:', dataEdit)
      isEdit.value = true
      formData.value.titulo = dataEdit.titulo
      formData.value.justificacion = dataEdit.justificacion
      formData.value.totalFlete = parseFloat(0).toFixed(2)
      formData.value.totalIgv = parseFloat(0).toFixed(2)
      formData.value.totalSub = parseFloat(dataEdit.subTotal).toFixed(2)
      formData.value.totalFinal = parseFloat(dataEdit.total).toFixed(2)
      formData.value.formaPago = { descripcion: dataEdit.formaPago }
      dataEdit.resumen.forEach(element => {
        const rowEdit = {
          categoriaProducto: { descripcion: element.categoria },
          codigo: element.codigo,
          codigoBarra: '',
          descripcion: element.producto,
          estado: true,
          idProducto: element.id_producto,
          img: null,
          marcaProducto: { descripcion: element.marca },
          productoNombre: `${element.codigo} - ${element.producto} [${element.marca}]`,
          tipoProductoLogistica: {},
          ubicacion: null,
          idDetallePedidoCompra: element.iddetallepedidocompra,
          unidadMedida: { descripcion: element.unidadmedida },
          precio: parseFloat(element.precio_actual).toFixed(2),
          cantidad: parseFloat(element.cantidad_solicitada).toFixed(2),
          cantidadReal: parseFloat(0).toFixed(2),
          igv: 0,
          flete: parseFloat(0).toFixed(2),
          precioCompra: parseFloat(Number(element.precio_actual) + Number(0) + Number(0)).toFixed(2),
          subtotal: parseFloat(element.precio_actual * element.cantidad_solicitada).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          isService: false }
        items.value.push(rowEdit)
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    initData()
    getParametros()
    return {
      documentoClienteRef,
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      titleForm,
      getParametros,
      presentaciones,
      presentacionSel,
      almacenOrigen,
      selectTipoVenta,
      selectMotivo,
      selectFormaPago,
      selectAlmacen,
      selectProveedor,
      selectProducto,
      selectunidadMedida,
      fieldsDetalle,
      changeProducto,
      items,
      sortBy,
      isBusy,
      addDetalle,
      cleanPreData,
      calcularSubtotal,
      deleteDetalle,
      changeCantidadPrecio,
      searchKey,
      formParse,
      changeunidadMedida,
      selectPesoTipoUnidadMedida,
      isVentaRelacionado,
      selecTipoTransporte,
      formatDateSend,
      handleVehicle,
      selectTipoIgv,
      selectMoneda,
      getProveedor,
      isMonedaSol,
      fileToUpload,
      isEdit,
    }
  },
}
</script>

  <style>

  </style>
