<template>
  <div>
    <b-card
      title="Input Groups with Buttons"
      no-body
      class="mb-0 pt-2 pl-2 pr-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor"
                  label-for="proveedor"
                >
                  <v-select
                    v-model="formData.proveedor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="razonSocial"
                    :options="selectProveedor"
                    :state="getValidationState(validationContext)"
                    @input="getProveedor()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Ruc Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Ruc Proveedor"
                  label-for="rucProveedor"
                >
                  <b-form-input
                    v-model="formData.rucProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Direccion Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Direccion Proveedor"
                  label-for="direccionProveedor"
                >
                  <b-form-input
                    v-model="formData.direccionProveedor"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
              style="display: none;"
            >
              <validation-provider
                #default="validationContext"
                name="Nro Comprobante"
                rules="required"
              >
                <b-form-group
                  label="Nro Comprobante"
                  label-for="nroComprobante"
                >
                  <b-form-input
                    v-model="formData.nroComprobante"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
              style="display: none;"
            >
              <validation-provider
                #default="validationContext"
                name="NroGuia"
                rules="required"
              >
                <b-form-group
                  label="NroGuia"
                  label-for="nroGuia"
                >
                  <b-form-input
                    v-model="formData.nroGuia"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="fechaEmision"
                rules="required"
              >
                <b-form-group
                  label="Fecha Emision"
                  label-for="fechaEmision"
                >
                  <b-form-input
                    v-model="formData.fechaEmision"
                    type="date"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="fechaRecepcion"
                rules="required"
              >
                <b-form-group
                  label="Fecha Recepcion"
                  label-for="fechaRecepcion"
                >
                  <b-form-input
                    v-model="formData.fechaRecepcion"
                    type="date"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Forma Pago"
                rules="required"
              >
                <b-form-group
                  label="Forma Pago"
                  label-for="formaPago"
                >
                  <v-select
                    v-model="formData.formaPago"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectFormaPago"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Almacen"
                rules="required"
              >
                <b-form-group
                  label="Almacen"
                  label-for="almacen"
                >
                  <v-select
                    v-model="formData.almacen"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectAlmacen"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Pedido Compra"
                rules="required"
              >
                <b-form-group
                  label="Pedido Compra"
                  label-for="pedidoCompra"
                >
                  <v-select
                    v-model="formData.pedido"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="codigo"
                    :options="selectPedido"
                    :state="getValidationState(validationContext)"
                    @input="changePedido"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
              style="display: none;"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo Igv"
                rules="required"
              >
                <b-form-group
                  label="Tipo Igv"
                  label-for="Tipo Igv"
                >
                  <v-select
                    v-model="formData.tipoIgv"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoIgv"
                    :state="getValidationState(validationContext)"
                    @input="getTipoIgv()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
              style="display: none;"
            >
              <validation-provider
                #default="validationContext"
                name="Moneda"
                rules="required"
              >
                <b-form-group
                  label="Moneda"
                  label-for="sujeto"
                >
                  <v-select
                    v-model="formData.moneda"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectMoneda"
                    :state="getValidationState(validationContext)"
                    @input="getMoneda()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
              style="display: none;"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo Cambio"
                rules="required"
              >
                <b-form-group
                  label="Tipo Cambio"
                  label-for="tipoCambio"
                >
                  <b-form-input
                    v-model="formData.tipoCambio"
                    type="number"
                    :disabled="!isMonedaSol"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
              style="display: none;"
            >
              <b-form-group
                label="Adjuntar Pdf"
                label-for="archivo pdf"
              >
                <b-form-file
                  id="fileToUpload"
                  v-model="fileToUpload"
                  name="fileToUpload"
                  accept="text/pdf"
                  placeholder="Seleccione archivo"
                  drop-placeholder="Drop file here..."
                  @change="fileUploaded"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar Producto"
                label-for="producto"
              >
                <v-select
                  v-model="formData.preProducto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="productoNombre"
                  :options="selectProducto"
                  @input="changeProducto"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Precio"
                label-for="precio"
              >
                <b-form-input
                  v-model="formData.prePrecio"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Cantidad"
                label-for="Cantidad"
              >
                <b-form-input
                  v-model="formData.preCantidad"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Unidad M."
                label-for="producto"
              >
                <v-select
                  v-model="formData.preUnidadMedida"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descripcion"
                  :options="selectunidadMedida"
                  @input="changeunidadMedida"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-col md="6">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="addDetalle"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="items"
                responsive
                :fields="fieldsDetalle"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros"
                :busy="isBusy"
              >
                <!-- Column: precio -->
                <template #cell(productoNombre)="data">
                  <b-form-input
                    v-if="!data.item.isService"
                    v-model="data.item.productoNombre"
                    :disabled="true"
                    size="sm"
                  />
                  <b-form-input
                    v-if="data.item.isService"
                    v-model="data.item.productoNombre"
                    size="sm"
                  />
                </template>
                <template #cell(inputUnidadMedida)="data">
                  <v-select
                    v-model="data.item.unidadMedida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="[data.item.unidadMedida]"
                    disabled
                    class="select-size-sm"
                  />
                </template>
                <!-- Column: precio -->
                <template #cell(inputPrecio)="data">
                  <b-form-input
                    v-model="data.item.precio"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'P')"
                  />
                </template>
                <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    :disabled="true"
                    @input="changeCantidadPrecio(data.item,'S')"
                  />
                </template>
                <template #cell(inputCantidadReal)="data">
                  <b-form-input
                    v-model="data.item.cantidadReal"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                  />
                </template>
                <template #cell(inputIgv)="data">
                  <b-form-input
                    v-model="data.item.igv"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'I')"
                  />
                </template>
                <template #cell(inputFlete)="data">
                  <b-form-input
                    v-model="data.item.flete"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'F')"
                  />
                </template>
                <template #cell(inputPrecioCompra)="data">
                  <b-form-input
                    v-model="data.item.precioCompra"
                    size="sm"
                    type="number"
                    step="0.01"
                    min="0"
                    :disabled="true"
                    @input="changeCantidadPrecio(data.item,'PC')"
                  />
                </template>
                <!-- Column: Cantidad -->
                <template #cell(inputCantidad)="data">
                  <b-form-input
                    v-model="data.item.cantidad"
                    type="number"
                    size="sm"
                    step="0.01"
                    min="0"
                    @input="changeCantidadPrecio(data.item,'C')"
                  />
                </template>
                <!-- Column: Subtotal -->
                <!-- <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    readonly
                    size="sm"
                  />
                </template> -->
                <!-- Column: Actions -->
                <template #cell(acciones)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="deleteDetalle(data.item)"
                    >
                      <feather-icon icon="XSquareIcon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="2"
            />
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Total Flete"
                label-for="totalFlete"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalFlete"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Sub Total"
                label-for="subTotal"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalSub"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Total Igv"
                label-for="totalIgv"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalIgv"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Total"
                label-for="total"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model="formData.totalFinal"
                  type="number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            />
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                type="submit"
                size="lg"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            />
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, VBTooltip, BCol, BRow, BFormGroup, BForm, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { useNotify } from '@/helpers/toast'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    BCard,
    // BFormDatepicker,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BFormInput,
    // BInputGroup,
    // BInputGroupAppend,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormFile,
    // BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const titleForm = ref('')
    const sortBy = ref(null)
    const isBusy = ref(false)
    const isVentaRelacionado = ref(false)
    const fileToUpload = ref(null)
    const blankData = {
      moneda: { descripcion: 'SOLES' },
      formaPago: { descripcion: 'CONTADO' },
      motivo: {},
      tipoCambio: '1',
      tipoDocumento: {},
      nroComprobante: '00',
      nroGuia: '00',
      tipoIgv: { descripcion: 'SIN IGV' },
      documentoSujeto: '',
      prePrecio: 0,
      preCantidad: 0,
      preUnidadMedida: {},
      totalFlete: parseFloat(0).toFixed(2),
      totalIgv: parseFloat(0).toFixed(2),
      totalSub: parseFloat(0).toFixed(2),
      totalFinal: parseFloat(0).toFixed(2),
      pedido: {},
    }
    const presentaciones = ref([])
    const presentacionSel = ref({})
    const selectPedido = ref([])
    const items = ref([])
    const fieldsDetalle = ref([
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '30%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '12%' } },
      { key: 'inputSubtotal', label: 'Sub total', thStyle: { width: '7%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '7%' } },
      { key: 'inputCantidadReal', label: 'Cantidad Real', thClass: 'd-none', tdClass: 'd-none', thStyle: { width: '7%' } },
      { key: 'inputPrecio', label: 'P. Unitatio', thStyle: { width: '7%' } },
      { key: 'inputIgv', label: 'Igv', thClass: 'd-none', tdClass: 'd-none', thStyle: { width: '7%' } },
      { key: 'inputFlete', label: 'Flete', thClass: 'd-none', tdClass: 'd-none', thStyle: { width: '7%' } },
      { key: 'inputPrecioCompra', label: 'P. Compra', thStyle: { width: '7%' } },
      // { key: 'inputCompra', label: 'P.Compra', thStyle: { width: '14%' } },
      //   { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '14%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
    ])
    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }
    const almacenOrigen = ref([])
    const selectTipoVenta = ref([])
    const selectMotivo = ref([])
    const selectFormaPago = ref([
      { descripcion: 'CREDITO' },
      { descripcion: 'CONTADO' },
    ])
    const selectAlmacen = ref([])
    const selectProveedor = ref([])
    const selectunidadMedida = ref([])
    const documentoClienteRef = Vue.ref<HTMLInputElement | null>(null)
    const selectTipoIgv = ref([
      // { descripcion: 'CLIENTE' },
      { descripcion: 'SIN IGV' },
      { descripcion: 'IGV INCLUIDO' },
      // { descripcion: 'IGV INAFECTO' },
    ])
    const selectMoneda = ref([
      { descripcion: 'SOLES' },
      { descripcion: 'DOLARES' },
    ])
    const selecTipoTransporte = ref([
      { descripcion: 'TRANSPORTE PUBLICO', id: '01' },
      // { descripcion: 'TRANSPORTE PRIVADO', id: '02' },
    ])
    const isMonedaSol = ref(false)
    const selectPesoTipoUnidadMedida = ref([
      { descripcion: 'KGM' },
      { descripcion: 'TNE' },
    ])
    const selectProducto = ref([])
    const initData = () => {
      // console.log('denyss')
      formData.value.tipo_de_transporte = { descripcion: 'TRANSPORTE PUBLICO', id: '01' }
      formData.value.motivo_de_traslado = { descripcion: 'TRASLADO EMISOR ITINERANTE CP', id: '18' }
      // formData.value.tipoDocumento = {}
    }

    const formatDateSend = date => {
      console.info('formatDateSend', date)
      let response = ''
      if (date !== null && date !== undefined && typeof (date) !== 'string') {
        response = `${date.day}-${date.month}-${date.year}`
      }
      if (typeof (date) === 'string') {
        const partDate = date.split('-')
        response = `${partDate[2]}-${partDate[1]}-${partDate[0]}`
      }
      return response
    }
    const getMoneda = () => {
      isMonedaSol.value = false
      if (formData.value.moneda.descripcion === 'DOLARES') {
        isMonedaSol.value = true
      }
    }
    const getNroComprobante = async () => {
      await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: formData.value.tipoDocumento.tipoDocumentoId, serie_id: formData.value.serie.serieId,
      })
        .then(response => {
          // Vue.swal.close()
          if (response) {
            console.log(formData.value.nro_comprobante)
            formData.value.nro_comprobante = response.nroDocumento
            console.log(formData.value.nro_comprobante)
          }
        })
    }
    const getParametros = async () => {
      const dataUser = JSON.parse(localStorage.getItem('userData'))
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          selectMotivo.value = []
          Vue.swal.showLoading()
          await store.dispatch('logistica/COMPRA_LOGISTICA_PARAMETROSL', { idTrabajador: dataUser.idTrabajador,
          })
            .then(response => {
              // console.info('ubigeo', response.ubigeo)
              if (response) {
                selectAlmacen.value = [response.almacen]
                formData.value.almacen = response.almacen
                selectProveedor.value = response.proveedor
                selectProducto.value = response.producto
                selectProducto.value.forEach((element, index) => {
                  selectProducto.value[index].productoNombre = `${element.codigo} - ${element.descripcion} [${element.marcaProducto.descripcion}]`
                })
                selectPedido.value = response.pedido
              }
              // response.items.forEach((element, index) => {
              //   if (index === 0) {
              //     formData.value.tipoMovimiento = element
              //   }
              // })
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
          Vue.swal.close()
        },
      })
    }
    const fileUploaded = event => {
      const file = event.target.files[0]
      console.log(file)
      const file1 = document.getElementById('fileToUpload').files[0]
      console.log(file1)
      fileToUpload.value = file1
    }

    const getProveedor = () => {
      console.log(formData.value.proveedor)
      formData.value.direccionProveedor = formData.value.proveedor.direccion
      formData.value.rucProveedor = formData.value.proveedor.ruc
    }
    const sendPdf = async idMaster => {
      const formDataUpload = new FormData()
      formDataUpload.append('file', fileToUpload.value)
      formDataUpload.append('idMaster', idMaster)
      formDataUpload.append('tabla', 'compra')
      await store.dispatch('logistica/DOCUMENTO_UPLOAD_CREATE', formDataUpload)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
    const formParse = () => {
      const form = {
        ordenCompra: {},
        detalleOrdenCompra: [],
      }
      const sunatVenta = {
        proveedor: formData.value.proveedor,
        almacen: formData.value.almacen,
        nroComprobante: formData.value.nroComprobante.toUpperCase(),
        nroGuia: formData.value.nroGuia,
        tipoIgv: formData.value.tipoIgv.descripcion,
        formaPago: formData.value.formaPago.descripcion,
        totalIgv: formData.value.totalIgv,
        moneda: formData.value.moneda.descripcion,
        tipoCambio: formData.value.tipoCambio,
        totalFlete: formData.value.totalFlete,
        subTotal: formData.value.totalSub,
        total: formData.value.totalFinal,
        fechaRecepcion: moment(formData.value.fechaRecepcion).format('DD/MM/YYYY'),
        fechaEmision: moment(formData.value.fechaEmision).format('DD/MM/YYYY'),
        estado: true,
        pedido: { idPedidoCompra: 1 },
        etapa: 'ACEPTADO',
      }
      form.ordenCompra = sunatVenta
      items.value.forEach(element => {
        // console.log(element)
        const row = {
          compra: { idCompra: 0 },
          producto: { idProducto: element.idProducto },
          cantidad: element.cantidad,
          subtotal: element.subtotal,
          precio: element.precio,
          precioCompra: element.precioCompra,
          flete: element.flete,
          igv: element.igv,
          cantidadReal: element.cantidadReal,
          estado: true,
        }
        form.detalleOrdenCompra.push(row)
      })
      console.log(JSON.stringify(form))
      return (form)
    }
    const onSubmit = async () => {
      try {
        Vue.swal({
          title: 'Registrando el Orden Compra',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            Vue.swal.showLoading()
            const service = 'logistica/ORDEN_COMPRA_LOGISTICA_CREATE'
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, formParse())
              .then(async response => {
                if (fileToUpload.value !== null) {
                  await sendPdf(response.result.idCompra)
                }
                Vue.swal.close()
                notify('Respuesta', response.message, 'success')
                items.value = []
                resetData()
                getParametros()
              })
              .catch(error => {
                Vue.swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        Vue.swal.close()
        notify('Error', error.message, 'danger')
      }
    }

    const searchVenta = async () => {
      console.log(formData.value.nro_venta)
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store
            .dispatch('comercial/VENTAS_FIND_ALL', {
              idEmpresa: 1,
              limit: 1,
              query: formData.value.nro_venta,
              page: 1,
              almacen: -1,
              sortBy: 'ventaId%7CDESC',
              filtro_venta: '-1',
            })
            .then(response => {
              console.log(response)
              const clienteDato = (response.items[0].clienteDato).split('|')
              console.log(clienteDato[2])
              // const origenJr = 'JR. FRANCISCO PIZARRO NRO. 860 ---- OTROS MORALES - SAN MARTIN - SAN MARTIN'
              // const origenUbigeo = '220910'
              formData.value.documentoCliente = `${clienteDato[2]}`
              formData.value.razon_social = `${clienteDato[0]}`
              formData.value.email = response.items[0].email
              // formData.value.punto_de_partida_ubigeo = origenUbigeo
              // formData.value.punto_de_partida_direccion = origenJr
              response.items[0].detalleVenta.forEach(ele => {
                const row = {
                  productoNombre: ele.productoDato,
                  precio: ele.precio,
                  cantidad: ele.cantidad,
                  subtotal: parseFloat(ele.precio * ele.cantidad).toFixed(2),
                  key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
                  unidadMedida: ele.unidadMedidaDato,
                  isService: false }
                /* if (searchKey(row) !== '') {
                throw new Error('EL PRODUCTO YA FUE AGREDADO')
              } */
                console.log({ row })
                items.value.push(row)
              })

              Vue.swal.close()
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
        },
      })
    }
    const getSearchDocument = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.documentoCliente,
          tipo_documento: tDocumento,
        })
        .then(response => {
          console.log(response)
          formData.value.razon_social = response.data.razonSocial
          formData.value.direccion = response.data.direccion
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
        // this.isBusy = false
    }
    const getSearchDocumentConductor = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.conductor_documento_numero,
          tipo_documento: tDocumento,
        })
        .then(response => {
          formData.value.conductor_denominacion = response.data.razonSocial
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
        // this.isBusy = false
    }

    const changeProducto = () => {
      console.log(formData.value.preProducto)
      // formData.value.prePrecio = (formData.value.preProducto.precioDefault).toFixed(2)
      selectunidadMedida.value = [formData.value.preProducto.unidadMedida]
      formData.value.preUnidadMedida = formData.value.preProducto.unidadMedida
    }
    const changeunidadMedida = () => {
      console.log('unidad')
    }
    const cleanPreData = () => {
      formData.value.preCantidad = null
      formData.value.prePrecio = null
      formData.value.preProducto = {}
    }
    const searchKey = row => {
      let seacrh = ''
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          seacrh = index
        }
      })
      return seacrh
    }
    const calcularSubtotal = () => {
      let subtotal = 0
      let flete = 0
      let igv = 0
      items.value.forEach(value => {
        console.log((Number(value.flete) * Number(value.cantidad)))
        subtotal += Number(value.subtotal)
        flete += (Number(value.flete) * Number(value.cantidad))
        igv += (Number(value.igv) * Number(value.cantidad))
      })
      console.info(subtotal, flete, igv)
      console.log(formData.value)
      formData.value.totalFlete = parseFloat(Number(flete)).toFixed(2)
      formData.value.totalIgv = parseFloat(Number(igv)).toFixed(2)
      formData.value.totalSub = parseFloat(Number(subtotal)).toFixed(2)
      formData.value.totalFinal = parseFloat(Number(subtotal) + Number(igv)).toFixed(2)
      console.log(formData.value)
    }
    // const calculateTotal = () => {
    //   let subT = 0
    //   items.value.forEach(element => {
    //     subT += Number(element.subtotal)
    //   })
    //   formData.value.subTotal = parseFloat(subT).toFixed(2)
    // }
    const addDetalle = () => {
      try {
        // const valPro = formData.value.preProducto === null
        // console.log({ valPro })
        // console.info(formData.value.preProducto, formData.value.preCantidad, formData.value.prePrecio)
        // VALIDAR CANTIDAD
        if (formData.value.preProducto === '' || formData.value.preProducto === 0 || formData.value.preProducto === undefined || formData.value.preProducto === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE SELECCIONAR UN PRODUCTO')
        }
        if (formData.value.preCantidad === '' || formData.value.preCantidad === 0 || formData.value.preCantidad === undefined || formData.value.preCantidad === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE INGRESAR LA CANTIDAD')
        }
        // VALIDAR PRECIO
        if (formData.value.prePrecio === '' || formData.value.prePrecio === 0 || formData.value.prePrecio === undefined || formData.value.prePrecio === null) {
          formData.value.prePrecio.focus()
          throw new Error('TIENE QUE INGRESAR EL PRECIO')
        }
        // VALIDAR SI EXISTE EL PRODUCTO
        const igvPre = formData.value.tipoIgv.descripcion === 'IGV INCLUIDO' ? parseFloat((Number(formData.value.prePrecio) * 0.18)).toFixed(2) : parseFloat(0).toFixed(2)
        const row = {
          ...formData.value.preProducto,
          precio: parseFloat(formData.value.prePrecio).toFixed(2),
          cantidad: parseFloat(formData.value.preCantidad).toFixed(2),
          cantidadReal: parseFloat(0).toFixed(2),
          igv: igvPre,
          flete: parseFloat(0).toFixed(2),
          precioCompra: parseFloat(Number(formData.value.prePrecio) + Number(igvPre) + Number(0)).toFixed(2),
          subtotal: parseFloat(formData.value.prePrecio * formData.value.preCantidad).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          unidadMedida: formData.value.preUnidadMedida,
          isService: false }
        if (searchKey(row) !== '') {
          throw new Error('EL PRODUCTO YA FUE AGREDADO')
        }
        console.log({ row })
        items.value.push(row)
        formData.value.preUnidadMedida = []
        formData.value.preCantidad = null
        cleanPreData()
        formData.value.preProducto = null
        calcularSubtotal()
        notify('', 'Se agrego el detalle', 'success')
      } catch (error) {
        console.log(error)
        notify('Error', error.message, 'danger')
      }
    }

    const addDetalleLibre = () => {
      const row = {
        productoNombre: '',
        precio: parseFloat(0).toFixed(2),
        cantidad: parseFloat(0).toFixed(2),
        subtotal: parseFloat(0).toFixed(2),
        key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
        unidadMedida: { descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 },
        isService: true }
      if (searchKey(row) !== '') {
        throw new Error('EL PRODUCTO YA FUE AGREDADO')
      }
      console.log({ row })
      items.value.push(row)
    }
    const deleteDetalle = row => {
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          items.value.splice(index, 1)
        }
      })
      calcularSubtotal()
    }
    const changeCantidadPrecio = (row, tipo) => {
    // precioCompra = PC
      console.info(row, tipo, items)
      items.value.forEach((element, index) => {
        if (element.key === row.key) {
          items.value[index].subtotal = parseFloat(Number(row.cantidad) * Number(row.precio)).toFixed(2)
          items.value[index].precioCompra = parseFloat(Number(row.precio) + Number(row.igv) + Number(row.flete)).toFixed(2)
        }
      })
      calcularSubtotal()
      // row.subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
    }
    const getTipoIgv = () => {
      console.log(formData.value.tipoIgv.descripcion)
      if (formData.value.tipoIgv.descripcion === 'SIN IGV') {
        items.value.forEach((element, index) => {
          items.value[index].igv = 0
          items.value[index].precioCompra = Number(items.value[index].precio) + Number(items.value[index].flete) + 0
        })
      }
      if (formData.value.tipoIgv.descripcion === 'IGV INCLUIDO') {
        items.value.forEach((element, index) => {
          items.value[index].igv = parseFloat(items.value[index].precio * 0.18).toFixed(2)
          items.value[index].precioCompra = Number(items.value[index].precio) + Number(items.value[index].flete) + Number(parseFloat(items.value[index].precio * 0.18).toFixed(2))
        })
      }
      calcularSubtotal()
    }
    const changePedido = () => {
      console.log(formData.value.pedido)

      formData.value.pedido.resumenCotizacion.forEach(element => {
        if (element.ganador) {
          console.log(element)
          formData.value.proveedor = { direccion: '-', idProveedor: element.idproveedor, nombreComercial: element.proveedor, razonSocial: element.proveedor, ruc: element.ruc }
          formData.value.rucProveedor = element.ruc
          formData.value.direccionProveedor = '-'
        }
      })

      formData.value.pedido.resumen.forEach(element => {
        console.log(element)
        const row = {
          categoriaProducto: { descripcion: element.categoria },
          codigo: element.codigo,
          codigoBarra: '',
          descripcion: element.producto,
          estado: true,
          idProducto: element.id_producto,
          img: null,
          marcaProducto: { descripcion: element.marca },
          productoNombre: `${element.codigo} - ${element.producto} [${element.marca}]`,
          tipoProductoLogistica: {},
          ubicacion: null,
          idDetallePedidoCompra: element.iddetallepedidocompra,
          unidadMedida: { descripcion: element.unidadmedida },
          precio: parseFloat(element.precio_actual).toFixed(2),
          cantidad: parseFloat(element.cantidad_solicitada).toFixed(2),
          cantidadReal: parseFloat(0).toFixed(2),
          igv: 0,
          flete: parseFloat(0).toFixed(2),
          precioCompra: parseFloat(Number(element.precio_actual) + Number(0) + Number(0)).toFixed(2),
          subtotal: parseFloat(element.precio_actual * element.cantidad_solicitada).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          isService: false }
        items.value.push(row)
      })
      calcularSubtotal()
    }
    const handleVehicle = data => {
      if (data?.mtcPartida) {
        formData.value.numero_de_registro_mtc = data.mtcPartida
      } else {
        formData.value.numero_de_registro_mtc = ''
        notify('Error', 'El vehículo no cuenta con registro de mercancía en el MTC.', 'danger')
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    initData()
    getParametros()
    return {
      documentoClienteRef,
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      titleForm,
      getParametros,
      presentaciones,
      presentacionSel,
      almacenOrigen,
      selectTipoVenta,
      selectMotivo,
      selectFormaPago,
      selectAlmacen,
      getNroComprobante,
      selectProveedor,
      getSearchDocument,
      selectProducto,
      selectunidadMedida,
      fieldsDetalle,
      changeProducto,
      items,
      sortBy,
      isBusy,
      addDetalle,
      addDetalleLibre,
      cleanPreData,
      calcularSubtotal,
      deleteDetalle,
      changeCantidadPrecio,
      searchKey,
      formParse,
      changeunidadMedida,
      selectPesoTipoUnidadMedida,
      getSearchDocumentConductor,
      isVentaRelacionado,
      searchVenta,
      selecTipoTransporte,
      formatDateSend,
      handleVehicle,
      selectTipoIgv,
      selectMoneda,
      getProveedor,
      isMonedaSol,
      getMoneda,
      fileToUpload,
      getTipoIgv,
      sendPdf,
      fileUploaded,
      selectPedido,
      changePedido,
    }
  },
}
</script>

  <style>

  </style>
