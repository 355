<template>
  <div>
    <b-card
      title="Input Groups with Buttons"
      no-body
      class="mb-0 pt-2 pl-2 pr-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Movimiento"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Movimiento"
                  label-for="tipoMovimiento"
                >
                  <v-select
                    v-model="formData.tipoMovimiento"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoMovimiento"
                    :state="getValidationState(validationContext)"
                    @input="getParametros()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Motivos"
                rules="required"
              >
                <b-form-group
                  label="Motivos"
                  label-for="serie"
                >
                  <v-select
                    v-model="formData.motivo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectMotivo"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Codigo Movimiento"
                rules="required"
              >
                <b-form-group
                  label="Codigo Movimiento"
                  label-for="codigoMovimiento"
                >
                  <b-form-input
                    v-model="formData.codigoMovimiento"
                    :state="getValidationState(validationContext)"
                    :disabled="true"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Documento"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Documento"
                  label-for="tipoDocumento"
                >
                  <v-select
                    v-model="formData.tipoDocumento"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoDocumento"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Nro Comprobante"
                rules="required"
              >
                <b-form-group
                  label="Nro Comprobante"
                  label-for="nroComprobante"
                >
                  <b-form-input
                    v-model="formData.nroComprobante"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Almacen Origen"
                rules="required"
              >
                <b-form-group
                  label="Almacen Origen"
                  label-for="almacenOrigen"
                >
                  <v-select
                    v-model="formData.almacenOrigen"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectAlmacenOrigen"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo Sujeto/Destino"
                rules="required"
              >
                <b-form-group
                  label="Tipo Sujeto/Destino"
                  label-for="forma_print"
                >
                  <v-select
                    v-model="formData.tipoSujeto"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoSujeto"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="sujeto"
                rules="required"
              >
                <b-form-group
                  label="Buscar sujeto/Destino"
                  label-for="sujeto"
                >
                  <v-select
                    v-model="formData.sujeto"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectSujeto"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Documento Sujeto"
                rules="required"
              >
                <b-form-group
                  label="Documento Sujeto"
                  label-for="documentoSujeto"
                >
                  <b-form-input
                    v-model="formData.documentoSujeto"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar Producto"
                label-for="producto"
              >
                <v-select
                  v-model="formData.preProducto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="productoNombre"
                  :options="selectProducto"
                  @input="changeProducto"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Precio"
                label-for="precio"
              >
                <b-form-input
                  v-model="formData.prePrecio"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Cantidad"
                label-for="Cantidad"
              >
                <b-form-input
                  v-model="formData.preCantidad"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Unidad M."
                label-for="producto"
              >
                <v-select
                  v-model="formData.preUnidadMedida"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descripcion"
                  :options="selectunidadMedida"
                  @input="changeunidadMedida"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-col md="6">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="addDetalle"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="items"
                responsive
                :fields="fieldsDetalle"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros"
                :busy="isBusy"
              >
                <!-- Column: precio -->
                <template #cell(productoNombre)="data">
                  <b-form-input
                    v-if="!data.item.isService"
                    v-model="data.item.productoNombre"
                    :disabled="true"
                    size="sm"
                  />
                  <b-form-input
                    v-if="data.item.isService"
                    v-model="data.item.productoNombre"
                    size="sm"
                  />
                </template>
                <template #cell(inputUnidadMedida)="data">
                  <v-select
                    v-model="data.item.unidadMedida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="[data.item.unidadMedida]"
                    disabled
                    class="select-size-sm"
                  />
                </template>
                <!-- Column: precio -->
                <template #cell(inputPrecio)="data">
                  <b-form-input
                    v-model="data.item.precio"
                    size="sm"
                    type="number"
                    @input="changeCantidadPrecio(data.item,'P')"
                  />
                </template>
                <!-- Column: Cantidad -->
                <template #cell(inputCantidad)="data">
                  <b-form-input
                    v-model="data.item.cantidad"
                    type="number"
                    step="any"
                    size="sm"
                  />
                </template>
                <!-- Column: Subtotal -->
                <!-- <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    readonly
                    size="sm"
                  />
                </template> -->
                <!-- Column: Actions -->
                <template #cell(acciones)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="deleteDetalle(data.item)"
                    >
                      <feather-icon icon="XSquareIcon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                type="submit"
                size="lg"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            />
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, VBTooltip, BCol, BRow, BFormGroup, BForm, BFormInput, BButton, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { useNotify } from '@/helpers/toast'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    // BFormDatepicker,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BFormInput,
    // BInputGroup,
    // BInputGroupAppend,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BTable,
    BDropdown,
    BDropdownItem,
    // BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const titleForm = ref('')
    const sortBy = ref(null)
    const isBusy = ref(false)
    const isVentaRelacionado = ref(false)
    const blankData = {
      tipoMovimiento: { descripcion: 'SALIDA' },
      motivo: {},
      codigoMovimiento: '',
      tipoDocumento: {},
      nroComprobante: '',
      tipoSujeto: { descripcion: 'ALMACEN' },
      documentoSujeto: '',
      prePrecio: 0,
      preCantidad: 0,
      preUnidadMedida: {},
    }
    const presentaciones = ref([])
    const presentacionSel = ref({})
    const items = ref([])
    const fieldsDetalle = ref([
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '35%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '18%' } },
      { key: 'inputPrecio', label: 'Precio', thStyle: { width: '14%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '14%' } },
      // { key: 'inputCompra', label: 'P.Compra', thStyle: { width: '14%' } },
      //   { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '14%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
    ])
    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }
    const almacenOrigen = ref([])
    const selectTipoVenta = ref([])
    const selectMotivo = ref([])
    const selectTipoDocumento = ref([
      { descripcion: 'FACTURA' },
      { descripcion: 'BOLETA' },
    ])
    const selectAlmacenOrigen = ref([])
    const selectSujeto = ref([])
    const selectunidadMedida = ref([])
    const documentoClienteRef = Vue.ref<HTMLInputElement | null>(null)
    const userProfile = ref(localStorage.getItem('userProfile'))
    const userData = ref(JSON.parse(localStorage.getItem('userData')))
    const selectTipoSujeto = ref([
      // { descripcion: 'CLIENTE' },
      { descripcion: 'PROVEEDOR' },
      { descripcion: 'ALMACEN' },
    ])
    const selectTipoMovimiento = ref([
      { descripcion: 'ENTRADA' },
      { descripcion: 'SALIDA' },
    ])
    const selecTipoTransporte = ref([
      { descripcion: 'TRANSPORTE PUBLICO', id: '01' },
      // { descripcion: 'TRANSPORTE PRIVADO', id: '02' },
    ])

    const selectPesoTipoUnidadMedida = ref([
      { descripcion: 'KGM' },
      { descripcion: 'TNE' },
    ])
    const selectProducto = ref([])
    const initData = () => {
      // console.log('denyss')
      formData.value.tipo_de_transporte = { descripcion: 'TRANSPORTE PUBLICO', id: '01' }
      formData.value.motivo_de_traslado = { descripcion: 'TRASLADO EMISOR ITINERANTE CP', id: '18' }
      // formData.value.tipoDocumento = {}
    }

    const formatDateSend = date => {
      console.info('formatDateSend', date)
      let response = ''
      if (date !== null && date !== undefined && typeof (date) !== 'string') {
        response = `${date.day}-${date.month}-${date.year}`
      }
      if (typeof (date) === 'string') {
        const partDate = date.split('-')
        response = `${partDate[2]}-${partDate[1]}-${partDate[0]}`
      }
      return response
    }
    const getNroComprobante = async () => {
      await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: formData.value.tipoDocumento.tipoDocumentoId, serie_id: formData.value.serie.serieId,
      })
        .then(response => {
          // Vue.swal.close()
          if (response) {
            console.log(formData.value.nro_comprobante)
            formData.value.nro_comprobante = response.nroDocumento
            console.log(formData.value.nro_comprobante)
          }
        })
    }
    const getParametros = async () => {
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          selectMotivo.value = []
          Vue.swal.showLoading()
          await store.dispatch('logistica/MOTIVO_MOVIMIENTO_FIND_ALL', { tipo: 'grilla', tipoMovimiento: formData.value.tipoMovimiento.descripcion, limit: 100, page: 1, query: '', sortBy: 'idMotivoMovimiento%7CDESC',
          })
            .then(response => {
              // console.info('ubigeo', response.ubigeo)
              if (response) {
                selectMotivo.value = response.items
              }
              // response.items.forEach((element, index) => {
              //   if (index === 0) {
              //     formData.value.tipoMovimiento = element
              //   }
              // })
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
          await store.dispatch('logistica/MOVIMIENTO_LASTCORRELATIVO_ALL', { tipoMovimiento: formData.value.tipoMovimiento.descripcion,
          })
            .then(response => {
              // console.info('ubigeo', response.ubigeo)
              if (response) {
                formData.value.codigoMovimiento = response.correlativo
              }
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
          await store.dispatch('logistica/ALMACEN_FIND_ALL', { limit: -1, page: -1, tipo: 'grilla', query: '', sortBy: '',
          })
            .then(response => {
              // console.info('ubigeo', response.ubigeo)
              if (response) {
                selectAlmacenOrigen.value = response.items
                if (formData.value.tipoSujeto.descripcion === 'ALMACEN') {
                  selectSujeto.value = response.items
                }
              }
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
          await store.dispatch('logistica/PRODUCTO_LOGISTICA_FIND_ALL', { limit: -1, page: -1, tipo: 'grilla', query: '', sortBy: '',
          })
            .then(response => {
              // console.info('ubigeo', response.ubigeo)
              if (response) {
                selectProducto.value = response.items
                selectProducto.value.forEach((element, index) => {
                  selectProducto.value[index].productoNombre = `${element.codigo} - ${element.descripcion} [${element.marcaProducto.descripcion}]`
                })
              }
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
          Vue.swal.close()
        },
      })
    }
    const formParse = () => {
      const form = {
        movimiento: {},
        detalleMovimiento: [],
      }
      const sunatVenta = {
        almacenDestino: formData.value.almacenDestino,
        codigoMovimiento: formData.value.codigoMovimiento,
        idAlmacen: formData.value.almacenOrigen.idAlmacen,
        idAlmacenOrigen: formData.value.almacenOrigen.idAlmacen,
        idSujeto: formData.value.sujeto.idAlmacen,
        motivoMovimiento: formData.value.motivo,
        nroComprobante: formData.value.nroComprobante,
        sujeto: formData.value.sujeto.descripcion,
        tipoComprobante: formData.value.tipoDocumento.descripcion,
        tipoSujeto: formData.value.tipoSujeto.descripcion,
        estado: true,
      }
      form.movimiento = sunatVenta
      items.value.forEach(element => {
        // console.log(element)
        const row = {
          cantidad: element.cantidad,
          precioCompra: element.precio,
          movimiento: { idMovimiento: 0 },
          producto: { idProducto: element.idProducto },
        }
        form.detalleMovimiento.push(row)
      })
      // console.log(JSON.stringify(form))
      return (form)
    }
    const onSubmit = async () => {
      try {
        Vue.swal({
          title: 'Registrando el Movimiento',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            Vue.swal.showLoading()
            const service = 'logistica/MOVIMIENTO_CREATE'
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, formParse())
              .then(response => {
                Vue.swal.close()
                notify('Respuesta', response.message, 'success')
                items.value = []
                resetData()
                getParametros()
              })
              .catch(error => {
                Vue.swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        Vue.swal.close()
        notify('Error', error.message, 'danger')
      }
    }

    const searchVenta = async () => {
      console.log(formData.value.nro_venta)
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store
            .dispatch('comercial/VENTAS_FIND_ALL', {
              idEmpresa: 1,
              limit: 1,
              query: formData.value.nro_venta,
              page: 1,
              almacen: -1,
              sortBy: 'ventaId%7CDESC',
              filtro_venta: '-1',
            })
            .then(response => {
              console.log(response)
              const clienteDato = (response.items[0].clienteDato).split('|')
              console.log(clienteDato[2])
              // const origenJr = 'JR. FRANCISCO PIZARRO NRO. 860 ---- OTROS MORALES - SAN MARTIN - SAN MARTIN'
              // const origenUbigeo = '220910'
              formData.value.documentoCliente = `${clienteDato[2]}`
              formData.value.razon_social = `${clienteDato[0]}`
              formData.value.email = response.items[0].email
              // formData.value.punto_de_partida_ubigeo = origenUbigeo
              // formData.value.punto_de_partida_direccion = origenJr
              response.items[0].detalleVenta.forEach(ele => {
                const row = {
                  productoNombre: ele.productoDato,
                  precio: ele.precio,
                  cantidad: ele.cantidad,
                  subtotal: parseFloat(ele.precio * ele.cantidad).toFixed(2),
                  key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
                  unidadMedida: ele.unidadMedidaDato,
                  isService: false }
                /* if (searchKey(row) !== '') {
                throw new Error('EL PRODUCTO YA FUE AGREDADO')
              } */
                console.log({ row })
                items.value.push(row)
              })

              Vue.swal.close()
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
        },
      })
    }
    const getSearchDocument = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.documentoCliente,
          tipo_documento: tDocumento,
        })
        .then(response => {
          console.log(response)
          formData.value.razon_social = response.data.razonSocial
          formData.value.direccion = response.data.direccion
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
        // this.isBusy = false
    }
    const getSearchDocumentConductor = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.conductor_documento_numero,
          tipo_documento: tDocumento,
        })
        .then(response => {
          formData.value.conductor_denominacion = response.data.razonSocial
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
        // this.isBusy = false
    }

    const changeProducto = () => {
      console.log(formData.value.preProducto)
      // formData.value.prePrecio = (formData.value.preProducto.precioDefault).toFixed(2)
      selectunidadMedida.value = [formData.value.preProducto.unidadMedida]
      formData.value.preUnidadMedida = formData.value.preProducto.unidadMedida
    }
    const changeunidadMedida = () => {
      console.log('unidad')
    }
    const cleanPreData = () => {
      formData.value.preCantidad = null
      formData.value.prePrecio = null
      formData.value.preProducto = {}
    }
    const searchKey = row => {
      let seacrh = ''
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          seacrh = index
        }
      })
      return seacrh
    }
    const calcularSubtotal = () => {
      let subtotal = 0
      items.value.forEach(value => {
        subtotal += parseFloat(value.subtotal)
      })
      formData.value.total = parseFloat(subtotal)
      formData.value.subTotal = parseFloat(subtotal)
    }
    const addDetalle = () => {
      try {
        // const valPro = formData.value.preProducto === null
        // console.log({ valPro })
        // console.info(formData.value.preProducto, formData.value.preCantidad, formData.value.prePrecio)
        // VALIDAR CANTIDAD
        if (formData.value.preProducto === '' || formData.value.preProducto === 0 || formData.value.preProducto === undefined || formData.value.preProducto === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE SELECCIONAR UN PRODUCTO')
        }
        if (formData.value.preCantidad === '' || formData.value.preCantidad === 0 || formData.value.preCantidad === undefined || formData.value.preCantidad === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE INGRESAR LA CANTIDAD')
        }
        // VALIDAR PRECIO
        if (formData.value.prePrecio === '' || formData.value.prePrecio === 0 || formData.value.prePrecio === undefined || formData.value.prePrecio === null) {
          formData.value.prePrecio.focus()
          throw new Error('TIENE QUE INGRESAR EL PRECIO')
        }
        // VALIDAR SI EXISTE EL PRODUCTO
        const row = {
          ...formData.value.preProducto,
          precio: formData.value.prePrecio,
          cantidad: formData.value.preCantidad,
          subtotal: parseFloat(formData.value.prePrecio * formData.value.preCantidad).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          unidadMedida: formData.value.preUnidadMedida,
          isService: false }
        if (searchKey(row) !== '') {
          throw new Error('EL PRODUCTO YA FUE AGREDADO')
        }
        console.log({ row })
        items.value.push(row)
        formData.value.preUnidadMedida = []
        formData.value.preCantidad = null
        cleanPreData()
        formData.value.preProducto = null
        calcularSubtotal()
        notify('', 'Se agrego el detalle', 'success')
      } catch (error) {
        console.log(error)
        notify('Error', error.message, 'danger')
      }
    }
    const addDetalleLibre = () => {
      const row = {
        productoNombre: '',
        precio: parseFloat(0).toFixed(2),
        cantidad: parseFloat(0).toFixed(2),
        subtotal: parseFloat(0).toFixed(2),
        key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
        unidadMedida: { descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 },
        isService: true }
      if (searchKey(row) !== '') {
        throw new Error('EL PRODUCTO YA FUE AGREDADO')
      }
      console.log({ row })
      items.value.push(row)
    }
    const deleteDetalle = row => {
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          items.value.splice(index, 1)
        }
      })
      calcularSubtotal()
    }
    const changeCantidadPrecio = (row, tipo) => {
      console.info(row, tipo, items)
      items.value.forEach((element, index) => {
        if (element.key === row.key) {
          items.value[index].subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
        }
      })
      calcularSubtotal()
      // row.subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
    }
    const handleVehicle = data => {
      if (data?.mtcPartida) {
        formData.value.numero_de_registro_mtc = data.mtcPartida
      } else {
        formData.value.numero_de_registro_mtc = ''
        notify('Error', 'El vehículo no cuenta con registro de mercancía en el MTC.', 'danger')
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    initData()
    getParametros()
    return {
      documentoClienteRef,
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      titleForm,
      getParametros,
      presentaciones,
      presentacionSel,
      almacenOrigen,
      selectTipoVenta,
      selectMotivo,
      selectTipoDocumento,
      selectAlmacenOrigen,
      getNroComprobante,
      selectTipoSujeto,
      selectSujeto,
      getSearchDocument,
      selectProducto,
      selectunidadMedida,
      fieldsDetalle,
      changeProducto,
      items,
      sortBy,
      isBusy,
      addDetalle,
      addDetalleLibre,
      cleanPreData,
      calcularSubtotal,
      deleteDetalle,
      changeCantidadPrecio,
      searchKey,
      formParse,
      changeunidadMedida,
      userProfile,
      userData,
      selectPesoTipoUnidadMedida,
      getSearchDocumentConductor,
      isVentaRelacionado,
      searchVenta,
      selecTipoTransporte,
      formatDateSend,
      handleVehicle,
      selectTipoMovimiento,
    }
  },
}
</script>

  <style>

  </style>
