import apiCall from '../../index'

const apiService = '/api-erpunigas/api/v1/logistica/documento'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findById(params) {
    const query = `/ver_documento?idMaster=${params.idMaster}&nombreArchivo=${params.nombreArchivo}&tabla=${params.tabla}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    const query = '/upload_documento' // `
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
