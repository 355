import service from '@/services/modules/logistica/pedido_compra'

export default {
  PEDIDO_COMPRA_LOGISTICA_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PEDIDO_COMPRA_LOGISTICA_FIND_BY_ID(_, params) {
    return new Promise((resolve, reject) => {
      service.findById(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PEDIDO_COMPRA_LOGISTICA_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PEDIDO_COMPRA_LOGISTICA_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PEDIDO_COMPRA_LOGISTICA_UPDATE_CABECERA(_, params) {
    return new Promise((resolve, reject) => {
      service.updateCabecera(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PEDIDO_COMPRA_LOGISTICA_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service.delete(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PEDIDO_COMPRA_LOGISTICA_PARAMETROSL(_, params) {
    return new Promise((resolve, reject) => {
      service.parametros(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
